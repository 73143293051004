import { IonRow, IonCol, IonImg } from "@ionic/react";
import React from "react";
import { fadeInToLeft, fadeInToRight} from "../../../utilities/animation";
import "./ourvision.css";

import OlderPeopleDouble from "../../../SVG/HomePage/FutureOutlook/OlderPeopleDouble.png"
import { useEffect, useRef } from "react";

export default function OurVision() {

    const pictureRef = useRef(null);
    const textRef = useRef(null);

    const [isVisible, setIsVisible] = React.useState(false);


    useEffect(() => {
    
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.target === textRef.current && entry.isIntersecting) {
                        setIsVisible(1);
                    }
                    if (entry.target === pictureRef.current && !entry.isIntersecting) {
                        setIsVisible(0);
                    }
                });
            },
            {
                threshold: 0.3
            }
        );
    
        const tRef = textRef.current;
        const iCRef = pictureRef.current;
    
        if (tRef) observer.observe(tRef);
        if (iCRef) observer.observe(iCRef);
    
        return () => {
            if (tRef) observer.unobserve(tRef);
            if (iCRef) observer.unobserve(iCRef);
        };
    }, []);
    
    
    useEffect(() => {
        const fadeInToRight_ImgRef = fadeInToRight(textRef);
        const fadeInToLeft_TextRef = fadeInToLeft(pictureRef);

            if (isVisible) {
                fadeInToRight_ImgRef.play();
                fadeInToLeft_TextRef.play();
            } 
        }, [isVisible]);
    

    return (
        <IonRow className="bg-OurVision" style = {{paddingBottom: "150px"}}>
            {/* <IonCol sizeLg="6" sizeXs="8" className="image-container">
                <IonImg ref={pictureRef} className="img-overlap first-image" src={OlderPeople} alt="futureOutlook" />
                <IonImg ref={pictureRef} className="img-overlap second-image" src={OlderPeople} alt="futureOutlook" />
            </IonCol> */}

            <IonCol ref = {pictureRef} className="col-p-vision" sizeLg="6" sizeXs="12" >
                <IonImg className = "double-img" src={OlderPeopleDouble} alt="Our Vision" />
            </IonCol>
            <IonCol ref = {textRef} className="col-p-vision" sizeLg="6" sizeXs="12" >
                <p className = "section-title">Unsere Vision</p>
                <p className="p-vision">
                    Wir sind Kristin, Dennis und Mario – drei motivierte Menschen, die neben unserem Studium oder unserer aktuellen Festanstellung gemeinsam Krameda gegründet haben, um Ihnen direkt vor Ort Unterstützung zu bieten.
                    </p>
                    <p className="p-vision">

                    Unsere Mission ist es, Menschen, die im Alltag Unterstützung benötigen, schnell und unkompliziert mit hilfsbereiten Menschen aus ihrer unmittelbaren Umgebung zu vernetzen. Unsere Plattform bietet eine einfache Möglichkeit, Helfer für alltägliche Aufgaben zu finden, sei es beim Einkaufen, im Haushalt oder bei der Begleitung zu Arztterminen.
                    </p>

                    <p className="p-vision">

                    Wir verstehen, wie wertvoll Zeit und Ressourcen sind, besonders für Angehörige, die sich um ihre Liebsten kümmern. Mit Krameda wollen wir nicht nur den Alltag der Senioren erleichtern, sondern qsauch ihren Kindern, Enkeln und anderen Beteiligten eine echte Entlastung bieten.
                    </p>

                    <p className="p-vision">

                    Unser Service übernimmt viele Aufgaben, die Sie bisher selbst erledigen mussten, sodass Sie Ihre wertvolle Zeit anderweitig nutzen können, während Ihre Liebsten in guten Händen sind. Gemeinsam möchten wir das Leben aller Beteiligten ein Stückchen leichter machen und für mehr Lebensqualität sorgen. Ihr Team von Krameda

                </p>
            </IonCol>
        </IonRow>
    )
}
//TODO: Darstelllungsfehler image 2


/* <IonGrid className="bg-futureOutlook">
<ThemedText variant="h1-dark">Unsere Vision</ThemedText>
<IonRow>
<IonGrid
    className="row-grid-vision"
    style={{
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 1s ease-in-out',
        flexDirection: "column"
    }}

>
    <IonRow>
        <p className="futureOutlook-label-vision">
            Wir wollen die Welt zu einem besseren Ort machen, indem Menschen, die Hilfe brauchen, mit Menschen zusammengebracht werden, die helfen wollen.
        </p>
        <br />
    </IonRow>
    <IonRow style={{ display: "flex", justifyContent: "center" }}>
        <p className="splitted-phrase">
            {phrases.map((phrase, index) => (
                <span className="span-phrase" key={index} style={{ opacity: index === currentPhraseIndex ? 1 : 0, transition: "opacity 1s ease-in-out" }}>
                    {phrase}
                </span>
            ))}
        </p>
    </IonRow>
</IonGrid>

</IonRow>
<IonRow
className="row-grid-vision"
style={{
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 1s ease-in-out',
}}>
<IonCol ref={textRef} sizeXl="12" sizeXs="12" style={{ display: "flex", flexDirection: "column", padding: "0", textAlign: "justify", alignItems: "center", justifyContent: "center" }}>
    <p className="futureOutlook-p-vision">
        Als junges Startup ist unsere Zeit neben Beruf und Studium knapp, weshalb sich unsere App derzeit noch in der Entwicklungsphase befindet.                     </p>
    <br />
    <p className="futureOutlook-p-vision">
        Auch unsere Website entwickeln wir stetig weiter, um zusätzliche Funktionen zu integrieren. Unser langfristiges Ziel ist es, eine Plattform zu schaffen, auf der sowohl Privatpersonen als auch Organisationen Inserate für Alltags- oder ehrenamtliche Helfer einstellen können. Helfer sollen diese Aufträge dann unkompliziert über die App oder Website annehmen können.

    </p>
</IonCol>
</IonRow>
</IonGrid> */
