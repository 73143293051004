import React, { Fragment, useEffect } from 'react';
import { IonModal, useIonAlert, IonCard, IonRow, IonGrid, IonCol, IonCardTitle, IonCardHeader, IonCardSubtitle, IonCardContent, IonCheckbox, IonButton, IonItemDivider, IonTextarea,  IonDatetime, IonSelect, IonSelectOption } from "@ionic/react";
import { Divider } from '@mui/material';
import { useState } from 'react'
import { IonContent,  IonHeader, IonToolbar, IonTitle, IonButtons } from '@ionic/react';
import ThemedText from '../../../components/ThemedText';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { setShoppingCart } from '../../../slices/shoppingCartSlice';
import { products } from '../../../utilities/helptasks';

const minutes = [0, 15, 30, 45];
const hours = [...Array(14).keys()].map(value => value + 7);

export default function ProductCase({ product }) {

    const [showAlert] = useIonAlert();
    const [modalOpen, setModalOpen] = useState(false);
    const [cartItem, setCartitem] = useState({});
    const [sonstigeValue, setSonstigeValue] = React.useState("");
    const [orderInfo, setOrderInfo] = React.useState("");
    const [neededHours, setNeededHours] = React.useState(1);

    const [actualDate, setActualDate] = React.useState(0);
    const [orderDate, setOrderDate] = React.useState(new Date().toISOString());

    const shoppingCart = useSelector((state) => state.shoppingCart.shoppingCartState);

    const dispatch = useDispatch();

    function checkDate(){
        if(actualDate === orderDate){
            showAlert({
                header: "Fehler beim Datum",
                subHeader: "Benötigt Änderung",
                message: `Das Startdatum muss in der Zukunft liegen. Bitte wählen Sie ein gültiges Datum aus.`,
                buttons: ["OK"]
            })
        }else{
            return true
        }
    }


    function configOrderInfos(event) {

        event.preventDefault();

        const formData = new FormData(event.target);

        const newCartItem = {
            segment: formData.get("productSegment"),
            price: formData.get("productPrice"),
            priceAdding: formData.get("productPriceAdding"),
            img: formData.get("productImg"),
            subtasks: formData.getAll("subtask"),
        };


        //Check if subtasks is empty
        if (newCartItem.subtasks.every(subtask => subtask === "")) {
            showAlert({
                header: "Keine Optionen gewählt",
                subHeader: "Wir brauchen mehr Infos",
                message: `Bitte wählen Sie mindestens eine Option aus ${newCartItem.segment} `,
                buttons: ["OK"]

            })
            return null;
        }
        setCartitem(newCartItem);
        setModalOpen(true);

    }

    function totalPrice(price, hours) {
        return price * hours; 
    }

    function checkSubstaskPrice(cartItem) {
        let price = 0;
        cartItem.subtasks.forEach(subtask => {  
            if(subtask !== "") {
                products[5].subtasks.forEach(productSubtask => { 
                    if (productSubtask.taskname === subtask) {
                        price += productSubtask.price;
                    }
                });
            }
        });
        return price;
    }

    function addToCart(event) {
        event.preventDefault();


        const order = {
            id: uuidv4(),
            startDateTime: orderDate,
            hours: neededHours,
            orderInfo: orderInfo,
            subtasks: cartItem.subtasks,
            segment: cartItem.segment,
            price: cartItem.price !== "Variierender Preis" ? cartItem.price : checkSubstaskPrice(cartItem),
            priceAdding: cartItem.priceAdding,
            img: cartItem.img,
            totalPrice: cartItem.price !== "Variierender Preis" ? totalPrice(cartItem.price, neededHours).toFixed(2) : checkSubstaskPrice(cartItem).toFixed(2),
            details:[
                    product.details.map(detail => {
                        return {question: detail.question, answer: event.target[detail.question].value}
                })
            ]
        }
        
        
        //RUNTIME CHECKS
        if(!checkDate() ){
            return null;
        }

        dispatch(setShoppingCart([...shoppingCart, order]));

        setModalOpen(false);
        showAlert({
            header: "Auftrag hinzugefügt",
            message: `Ihr Auftrag wurde erfolgreich zum Warenkorb hinzugefügt!`,
            buttons: ["OK"]

        })
    }



    useEffect(() => {
        const today = new Date();

        today.setHours(today.getHours() + 2);
        setOrderDate(today.toISOString());   
        setActualDate(today.toISOString());   
        
     }, [])


    return (
        <>
            <IonCol size="11" size-md="4" size-lg="3.5" style={{ display: "flex", justifyContent: "center" }}>
                <form onSubmit={configOrderInfos} style={{ display: "flex", justifyContent: "center" }}>
                    {/* HIDDEN */}
                    <input type="hidden" name="productSegment" value={product.segment} />
                    <input type="hidden" name="productPrice" value={product.price} />
                    <input type="hidden" name="productPriceAdding" value={product.priceAdding} />
                    <input type="hidden" name="productImg" value={product.img} />

                    <IonCard style={{ display: "flex", flexDirection: "column", width: "clamp(150px, 100%, 350px)", height: "clamp(650px, 100vw, 650px)", overflow: "hidden", }}>
                        <img className="card-image" src={product.img} alt="product" />
                        <IonCardHeader>
                            <IonCardTitle>{product.segment}</IonCardTitle>
                            <IonCardSubtitle>{product.price && `${product.price} € ${product.priceAdding}`}</IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent style={{ overflow: "auto", flexGrow: "1" }}>
                            {product.subtasks && product.subtasks.map((subtask, index) =>
                                <Fragment key = {index}>
                                    <IonCheckbox  justify="start" labelPlacement='end' key={subtask.id} name='subtask' value={subtask.taskname}><div className="label-text-wrapper">{subtask.taskname}</div></IonCheckbox>
                                    {subtask.price && (<p className = "p-botengaenge-subtasks">{subtask.price} € {subtask.priceAdding}</p>)}
                                </Fragment>

                            )}
                             {product.segment !== "Botengänge/ Beschaffungen"  &&
                                 (
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                               <IonCheckbox name='subtask' value={sonstigeValue} justify="start" labelPlacement='end' style={{ width: "fit-content" }}>
                                    Sonstige:
                                </IonCheckbox>
                                
                                <IonCol sizeXs='12' sizeLg='7' style={{ margin: "0px", padding: "0px" }}>
                                    <IonTextarea onIonInput={(e) => setSonstigeValue(e.detail.value)} rows={0} placeholder="Bitte angeben..."  />
                                </IonCol>
                            </div>
                            )}
                        </IonCardContent>
                        <IonCardContent>
                            <IonItemDivider style={{ alignSelf: "stretch" }} />
                            <IonRow style={{ justifyContent: "center", padding: "10px" }}>
                                <IonButton type="submit" style={{ whiteSpace: "normal", wordWrap: "break-word", fontSize: "12px" }}>Zum Warenkorb hinzufügen</IonButton>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                </form>
            </IonCol>
            
            <IonModal className="custom-modal" isOpen={modalOpen} onDidDismiss={() => setModalOpen(false)}>
                <IonHeader >
                    <IonToolbar>
                        <IonTitle>Auftrag: {cartItem.segment}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setModalOpen(false)}>Close</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent >
                    <form onSubmit={addToCart}>
                        <ThemedText variant="h2-dark">{cartItem.segment}</ThemedText>
                        <IonRow style = {{display: "flex", justifyContent:"center"}}>
                            <IonCol sizeXs='8' sizeSm='8' sizeMd="6" sizeLg="5" >
                                <img className="modal-img" src={cartItem.img} alt = "Bild vom Auftrag z.B. für Soziale Interaktion eien Gruppe von Menschen spielen ein Gemeinschaftsspiel"/>
                            </IonCol>
                            <IonCol sizeXs='12' sizeSm='12' sizeMd="12" sizeLg="7" style={{ padding: "10px" }}>
                                <br />
                                <p className = "p-modal">Preis: {cartItem.price} € {cartItem.priceAdding}</p>
                                <br />
                                <Divider />
                                <p className = "p-modal">Unteraufgaben</p>
                                <br/>
                                <IonRow style={{ padding: "5px" }}>

                                    {cartItem.subtasks && cartItem.subtasks.map((subtask, index) => {
                                        if (subtask !== "") {
                                            return (
                                                <IonGrid key={index} className="box-subtask" >
                                                    <p>{subtask}</p>
                                                </IonGrid>
                                            )
                                        }else{
                                            return null;
                                        }
                                    }
                                    )}
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        <IonItemDivider />
                        <IonRow>
                            <IonCol sizeXs='12' sizeMd="6" style={{ alignItems: "center", justifyContent: "center" }}>
                            <p className="p-modal">Datum</p>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <IonDatetime locale="de-DE" minuteValues = {minutes} hourValues={hours}  min={actualDate} value={orderDate} onIonChange={(e) => { setOrderDate(e.detail.value) }} className="custom-datetime">
                                        <span slot="time-label">Startzeitpunkt wählen: </span>
                                        <span slot="title" >Wähle dein Wunschdatum</span>

                                    </IonDatetime>
                                </div>

                            </IonCol>
                            <IonCol sizeXs='12' sizeMd = "6">
                                <IonRow style={{ display: "flex", flexDirection: "row" }}>
                                    <p className="p-modal">Stundenanzahl</p>
                                    <IonSelect interface="popover" placeholder="Stunden buchen" onIonChange = {(e) => setNeededHours(e.detail.value)} value={neededHours}  >
                                    {cartItem.price !== "Variierender Preis" ? 
                                            [...Array(25).keys()].map(value =>
                                            value !== 0 && <IonSelectOption key={value} value={value}>{value}  {value > 1 ? "Stunden": "Stunde"} </IonSelectOption>
                                        )   
                                        :   <IonSelectOption  key={1} value={1}> 1 Stunde </IonSelectOption>

                                    }
                                    </IonSelect>
                                </IonRow>
                                <IonRow>
                                    <p className="p-modal">
                                        Informationen zu ihrem Auftrag:
                                    </p>
                                    <textarea className = "modal-textarea" value={orderInfo} onChange={(e) => { setOrderInfo(e.target.value) }} style={{ width: "100%" }} rows={13} placeholder="Teilen Sie uns bitte alles mit, was Sie für notwendig halten, damit wir Ihnen bestmöglich helfen können!" />
                                </IonRow>
                            </IonCol>
                            <IonItemDivider />
                            <IonRow>
                                <p className="p-modal-title">
                                    Fast fertig! Wir brauchen nur noch ein paar Informationen um die maßgeschneiderte Hilfe zu bieten, die du verdient hast!
                                </p>
                                {product.details && product.details.map((detail,index) => {
                                    return (
                                        <IonRow key={index} style = {{width: "100%", padding: "10px"}}>
                                            <IonCol size = "12">
                                                <p className = "p-modal-details">{detail.question}</p>
                                            </IonCol>
                                            <IonCol size = "12">
                                                <input className = "input-details" type={detail.type} name={detail.question}/>
                                            </IonCol>
                                        </IonRow>
                                    )
                                })}                            
                            </IonRow>
                        </IonRow>
                        <IonItemDivider />
                        <IonRow style={{ justifyContent: "center", padding: "20px" }}>
                            <IonButton type="submit">Zum Warenkorb hinzufügen</IonButton>
                        </IonRow>
                    </form>
                </IonContent>
            </IonModal>
        </>
    );
}





















